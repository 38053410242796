import React from "react";
import { withTranslation } from "react-i18next";
import './Switch.css';

class Switch extends React.Component<any> {
    render() {
        const { id, defaultChecked, name, onSwitch, disabled } = this.props;
        return <>
            <input className="sCheckbox" type="checkbox" id={id} defaultChecked={defaultChecked} name={name} value={id} onClick={onSwitch} disabled={disabled} />
            <label className="sLabel" htmlFor={id}></label>
        </>;
    }

}

export default withTranslation()(Switch);