import React from 'react';
import './Title.scss';
import { withTranslation } from 'react-i18next';

class Title extends React.Component<any> {

  render() {
    const { children } = this.props;

    return <>
      <div className='page-title'>{children}</div>
    </>;
  }
}

export default withTranslation()(Title);