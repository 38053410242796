import React from 'react';
import { withTranslation } from 'react-i18next';
import PreferenceForm from './PreferenceForm';
import './UserPreferences.scss';

class CommunicationPreferences extends React.Component<any> {

  render() {
    const { t } = this.props;

    return <>
      <div>
        <h1>{t('navigation.communication_preferences')}</h1>
        <PreferenceForm />
      </div>
    </>;
  }
}

export default withTranslation()(CommunicationPreferences);