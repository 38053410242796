import React from 'react';
import { withTranslation } from 'react-i18next';
import { confirm } from './ConfirmManager';

const Confirm: React.FC<any> = (props) => {

  const { title, description, submit, t } = props;

  const backdropAction = (e: any) => { 
    // check backdrop click only
    if (e.target === e.currentTarget) {
      confirm.destroy();
    }
  }

  const cancelAction = () => {    
    confirm.destroy();
  }

  const submitAction = () => {
    submit();
    confirm.destroy();
  }

  return (
    <div className='content' onClick={backdropAction}>
      <div className='modal'>
        <div className='modal-header'>
          <h3>{title}</h3>
        </div>
        <div className='modal-description'><p>{description}</p></div>
        <div className='modal-footer'>
          <button type="button" className="secondary" onClick={cancelAction}>{t('cancel')}</button>
          <button type="button" className="primary" onClick={submitAction}>{t('validate')}</button>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Confirm);
