export enum Membership_Type {
  full_membership = "full_membership",
  membership_virage = "membership_virage",
  om_legendes = "om_legendes"
}

export default class Membership {
  type: Membership_Type
  id: string
  startDate: Date
  endDate: Date

  public constructor(id: string, programMember: any) {
    this.id = id;
    this.type = this.getMembershipType(programMember.Code)
    this.startDate = new Date(programMember.StartDate);
    this.endDate = new Date(programMember.EndDate || "01/01/2100");
  }

  public getMembershipType (memberCode: string): Membership_Type {
    switch (memberCode) {
      case 'MEMBER':
        return Membership_Type.full_membership
      case 'OMLEGENDES':
        return Membership_Type.om_legendes
      default:
        return Membership_Type.membership_virage;
    }
  }

  public static getActiveMembership (populations: string) {
    if (!populations) return;

    // Get OM Legendes population
    let membership = JSON.parse(populations).filter((field: any) => {
      return field.Code === 'OMLEGENDES' && (!field.EndDate || new Date(field.EndDate) >= new Date())
    })

    // Get Full Membership population
    if (membership.length === 0) {
      membership = JSON.parse(populations).filter((field: any) => {
        return field.Code === 'MEMBER' && (!field.EndDate || new Date(field.EndDate) >= new Date())
      })
    }

    // Get Virage Membership population
    if (membership.length === 0) {
      membership = JSON.parse(populations).filter((field: any) => {
        return field.Code === 'MEMBER_VIR' && (!field.EndDate || new Date(field.EndDate) >= new Date())
      })
    }

    if (membership.length === 0) return;

    const memberships = membership.sort((m1: any, m2: any) => {
      const maxDate = 8640000000000000;
      const endDate1 = new Date(m1.EndDate || maxDate);
      const endDate2 = new Date(m2.EndDate || maxDate);
      return endDate2.getTime() - endDate1.getTime();
    });

    return memberships[0];
  }
}