import React from 'react';
import { withTranslation } from 'react-i18next';
import './Carousel.scss';
import { Button, ButtonType } from '@om/design-system';

let uuid = 0

class Carousel extends React.Component<any> {
  uuid = uuid += 1
  uuidScroll = `scroll-${(uuid).toString()}`
  uuidSlide = `slide-${(uuid).toString()}`
  state = {
    isScroll: true
  }

  componentDidMount(): void {
    this.setState({ isScroll: document.getElementById(this.uuidSlide)!.scrollWidth > document.getElementById(this.uuidScroll)!.scrollWidth });
  }

  render() {
    const { t, children } = this.props;
    var { isScroll } = this.state;

    return <>
      <div className='scroll' id={this.uuidScroll}>
        <div className='slide' id={this.uuidSlide}>
          {children}
          <div className='desktop blur' style={ isScroll ? {} : { display: 'none' }}></div>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(Carousel);