import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonType, toast } from '@om/design-system';
import { AuthContext } from '../../contexts/AuthContext';
import User from '../../models/User';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getRedirect } from '../../utils';

const ID_FORM = "general-form'";

class GeneralForm extends React.Component<any> {

	state = {
		phone: null
	}

	capitalize(value: string) {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	isNullOrUndefined(value: any) {
		return value === undefined || value === null || value === '';
	}

	submit = (event: any) => {
		event.preventDefault();
		var form = document.getElementById(ID_FORM) as HTMLFormElement;
		var formData = new FormData(form);
		const { t } = this.props;
		const { token, user } = this.context as any;
		const phone = this.state.phone || user?.phoneNumber;

		const data = {
			givenName: user.firstName ? user.firstName : this.capitalize(formData.get('given_name')?.toString() || ""),
			familyName: user.lastName ? user.lastName : (formData.get('family_name')?.toString() || "").toUpperCase(),
			birthdate: user.birthdate ? user.birthdate?.toISOString().split('T')[0] : formData.get('birthdate'),
			gender: user.gender ? user.gender : formData.get('gender'),
			phone_number: phone || null
		};

		if (this.isNullOrUndefined(data.givenName) || this.isNullOrUndefined(data.familyName) || this.isNullOrUndefined(data.birthdate) || this.isNullOrUndefined(data.gender)) {
			toast.show({ content: t('form.error_required_field'), isError: true });
			return;
		}

		window.r5.updateProfile({ accessToken: token, data })
			.then(() => {
				// toast.show({ content: t('form.update_ok') });
				window.location.reload();
			})
			.catch((error: any) => {
				toast.show({ content: t('form.error'), isError: true });
			})
	}

	onChangePhone = (phone: any) => {
		this.setState({ phone });
	}

	sendEmailVerification = () => {
		const { t } = this.props;
		const { token } = this.context as any;

		window.r5
			.sendEmailVerification({ accessToken: token, redirectUrl: getRedirect() })
			.then(() => {
				toast.show({ content: t('form.email.success') });
			})
			.catch(() => {
				toast.show({ content: t('form.error'), isError: true });
			})
			;
	}

	toggleServiceclientToaster = () => {
		document.getElementById("serviceclient-toaster")?.classList.toggle("display-none")
	}

	render() {
		const { t } = this.props;
		const user = (this.context as any).user as User;

		return <>
			<div className="left-content-size">
				<h1>{t('profile.title')}</h1>
				<form id={ID_FORM} onSubmit={this.submit} className='form'>
					<div className='form-group'>
						<label htmlFor='given_name' className='required'>{`${t('signup.gender.title')}`}</label>
						{this.isNullOrUndefined(user.gender) ? <>
							<div className='btn-group width-100' role='group' aria-label='Gender radio button'>
								<input type='radio' className='btn-check' name='gender' id='male' autoComplete='off' defaultChecked={user.gender === 'male'} value='male' />
								<label className='btn btn-outline-primary' htmlFor='male'>{t('signup.gender.male')}</label>

								<input type='radio' className='btn-check' name='gender' id='female' autoComplete='off' defaultChecked={user.gender === 'female'} value='female' />
								<label className='btn btn-outline-primary' htmlFor='female'>{t('signup.gender.female')}</label>

								<input type='radio' className='btn-check' name='gender' id='other' autoComplete='off' defaultChecked={user.gender === 'other'} value='other' />
								<label className='btn btn-outline-primary' htmlFor='other'>{t('signup.gender.other')}</label>
							</div>
						</> : <>
							<div><span className='data-info'>{t('signup.gender.' + user.gender)}</span></div>
						</>}
					</div>

					<div className='form-group marginTop10'>
						<label htmlFor='given_name' className='required'>{`${t('signup.firstName')}`}</label>
						{this.isNullOrUndefined(user.firstName) ? <>
							<input type='text' className='form-control' id='given_name' name='given_name' placeholder={`${t('signup.firstName')}`} defaultValue={user.firstName} required />
						</> : <>
							<div><span className='data-info'>{user.firstName}</span></div>
						</>}
					</div>

					<div className='form-group'>
						<label htmlFor='family_name' className='required'>{`${t('signup.lastName')}`}</label>
						{this.isNullOrUndefined(user.lastName) ? <>
							<input type='text' className='form-control' id='family_name' name='family_name' placeholder={`${t('signup.lastName')}`} defaultValue={user.lastName} required />
						</> : <>
							<	div><span className='data-info'>{user.lastName}</span></div>
						</>}
					</div	>
					<div className='form-group'>
						<label htmlFor='birthdate' className='required'>{`${t('signup.birthdate')}`}</label>
						{this.isNullOrUndefined(user.birthdate) ? <>
							<input type='date' name='birthdate' id='birthdate' className='form-control' defaultValue={user.birthdate?.toISOString().split('T')[0]} max={new Date().toISOString().split('T')[0]} min='1900-01-01' required></input>
						</> : <>
							<div><span className='data-info'>{user.birthdate?.toLocaleDateString('en-GB')}</span></div>
						</>}
					</div>

					<div className='form-group'>
						<label htmlFor='email' className='required'>{t('signup.email')}</label>
						{this.isNullOrUndefined(user.email) ? <>
							<div className='form-group-floating' style={{ textAlign: 'right' }}>
								<input type='email' name='email' className='form-control' value={user.email} disabled></input>
								{user.emailVerified && <span id='ok_circle' role="img" aria-label={t('signup.verifiedEmail')} title={t('signup.verifiedEmail')} className='material-symbols-outlined'>check_circle</span>}
								{!user.emailVerified && <span id='ko_circle' role="img" aria-label={t('signup.notVerifiedEmail')} title={t('signup.notVerifiedEmail')} className='material-symbols-outlined'>warning</span>}
							</div>
						</> : <>
							<div className='form-group-floating' style={{ display: 'flex' }}>
								<div><span className='data-info'>{user.email}</span></div>
								{user.emailVerified && <span id='ok_circle' role="img" aria-label={t('signup.verifiedEmail')} title={t('signup.verifiedEmail')} className='material-symbols-outlined'>check_circle</span>}
								{!user.emailVerified && <span id='ko_circle' role="img" aria-label={t('signup.notVerifiedEmail')} title={t('signup.notVerifiedEmail')} className='material-symbols-outlined'>warning</span>}
							</div>
						</>}
						{!user.emailVerified && <Button type={ButtonType.Link} onClick={this.sendEmailVerification}>{t('signup.verify')}</Button>}
					</div>

					<div className='form-group'>
						<label htmlFor='phone'>{t('signup.phone')}</label>
						<div className='form-group-floating'>
							<PhoneInput name='phone' className='phone-control' defaultCountry="FR"
								onChange={this.onChangePhone} placeholder={t('signup.phone')} value={user.phoneNumber} />
						</div>
					</div>

					<Button type={ButtonType.Submit}>{t('save')}</Button>
				</form>
				<div>
					<div>{t('service_client.situation_change')}</div>
					<Button type={ButtonType.Link} href='https://www.om.fr/fr/contact'>{t('service_client.contact_button')}</Button>
				</div>
			</div>
		</>
	}
}

GeneralForm.contextType = AuthContext;
export default withTranslation()(GeneralForm);