import React from 'react';
import { withTranslation } from 'react-i18next';
import './NoMatch.scss';
import Title from '../../components/Title/Title';
import { Button, ButtonType } from '@om/design-system';

class NoMatch extends React.Component<any> {

  render() {
    const { t } = this.props;
    const { user } = this.context as any;

    return <>
      <div className={!!user ? 'wrapper-content' : 'wrapper-content not-connected'}>
        <div className="center-content-size center">
          <Title>404</Title>
          <div className='space first-letter-uppercase'>{t('not_found.msg')}</div>
          <Button type={ButtonType.Link} href='/'>{t('not_found.go_to_home_page')}</Button>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(NoMatch);