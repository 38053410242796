import { createContext } from 'react';
import User from '../models/User';

export class AuthSession {
    logged?: boolean = undefined
    user?: User = undefined
    token?: string = undefined
    setSession?: any

    constructor({ logged = undefined, user = undefined, token = undefined, setSession = undefined }: AuthSession) {
        this.logged = logged;
        this.user = user;
        this.token = token;
        this.setSession = setSession;
    }
}

export const AuthContext = createContext(new AuthSession({}));