export default class Address {
    country: string;
    streetAddress: string;
    addressComplement: string;
    locality: string;
    postalCode: string;

    public constructor(country: string, street: string, complement: string, locality: string, postalCode: string) {
        this.country = country;
        this.streetAddress = street;
        this.addressComplement = complement;
        this.locality = locality;
        this.postalCode = postalCode;
    }

    /**
     * Map a R5 server object into an Address object
     * @param r5 
     * @returns Address
     */
    static mapFromReachfive(r5: any): Address {   
        if (!r5) {
            return new Address("", "", "", "", "");
        }
     
        var address = new Address(
            r5.country,
            r5.streetAddress,
            r5.addressComplement,
            r5.locality,
            r5.postalCode
        );

        if (!!r5.streetAddress && r5.streetAddress.includes("\n")) {
            address.streetAddress =  r5.streetAddress.split("\n")[0];
            address.addressComplement =  r5.streetAddress.split("\n")[1];
        }
        return address;
    }
}