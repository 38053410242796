import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Business.scss';
import { useParams } from 'react-router-dom';
import ClubMemberAccount from './ClubMemberAccount';
import NotMember from './UserValidationPopup';
import { toast } from '@om/design-system';
import { AuthContext } from '../../contexts/AuthContext';

const Business = (props: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined as any);
  const { token } = useContext(AuthContext);

  const getUserStatus = async (id: String) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token }
      };

      //@ts-ignore
      fetch(`/api/membership/${id}`, options)
        .then((result: any) => {
          return result.json();
        })
        .then((json: any) => {
          resolve(json);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  useEffect(() => {
    if (!id) {
      toast.show({ content: t('form.error'), isError: true });
      return;
    }
    getUserStatus(id)
      .then((res: any) => {
        setUser(res);
      })
      .catch((err: any) => {
        toast.show({ content: t('form.error'), isError: true })
      });
  }, []);

  // while loading
  if (!user) {
    return <></>;
  }
  // if membership
  if (user?.is_member === true) {
    return <ClubMemberAccount user={user} />;
  }
  // not enough information
  return <NotMember user={user} />;
}

Business.contextType = AuthContext;
export default Business;