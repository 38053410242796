import { Button, ButtonType, TextField } from '@om/design-system';
import React from 'react';
import { withTranslation } from 'react-i18next';

const ID_FORM = "optout-form";

class Optout extends React.Component<any> {

  submit = (event: any) => {
    event.preventDefault();
  }

  render() {
    const { t } = this.props;

    return <>
      <div className='wrapper-content not-connected'>
        <div className='center-content-size'>
          <h1>{t('optout.title')}</h1>
          <form id={ID_FORM} onSubmit={this.submit} className="form">
            <div>
              <TextField name="email" type="email" placeholder={t('profile.email')} required />
            </div>
            <Button type={ButtonType.Submit}>{t('validate')}</Button>
          </form>
          <Button type={ButtonType.Link} href='/' className='center'>{t('backLogin')}</Button>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(Optout)