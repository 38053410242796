import { useTranslation } from 'react-i18next';

export const StepOne = () => {
  const { t } = useTranslation();
  
  return <>
    <div className='step'>
      <div className='content'>
        <div className='subheader'>{t('security.stepOne.sorry')}</div>
        <div className='medium subtitle'>{t('security.stepOne.instruction')}</div>
        <ul>
          <li>{t('security.stepOne.solutionOne')}&nbsp;<a href="/account/preferences" className='primary'>{t('security.stepOne.actionOne')}</a>.</li>
          <li>{t('security.stepOne.solutionTwo')}&nbsp;<a href="https://www.om.fr/fr/contact" className='primary'>{t('security.stepOne.actionTwo')}</a>.</li>
          <li>{t('security.stepOne.solutionThree')}</li>
        </ul>
      </div>
    </div>
  </>
}