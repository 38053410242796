import { Button, ButtonType } from '@om/design-system';
import { useTranslation } from 'react-i18next';
import './DeleteAccount.scss';

interface ConfirmProps {
  email: string
  onClosePopup: (e: any) => void;
  onDeleteAccount: (e: any) => void;
}

export const ConfirmPopup = (props: ConfirmProps) => {
  const { t } = useTranslation();
  const { email, onClosePopup, onDeleteAccount } = props;

  
  return <>
    <div className='confirm-popup'>
      <div>{t('security.warning', { email: email })}</div>
      <div className='actions'>
        <Button type={ButtonType.Secondary} onClick={onDeleteAccount}>{t('confirm.title')}</Button>
        <Button type={ButtonType.Primary} onClick={onClosePopup}>{t('cancel')}</Button>
      </div>
    </div>
  </>
}