import { Icon, IconType } from '@om/design-system'
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';

export const StepTwo = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  return <>
    <div className='step'>
      <div className='content'>
        <div className='medium subtitle'>{t('security.stepTwo.instruction')}</div>
        <div className='restriction-line'>
          <div><Icon type={IconType.Shield}></Icon></div>
          <div>{t('security.stepTwo.itemOne')}</div>
        </div>
        <div className='restriction-line'>
          <div><Icon type={IconType.Shield}></Icon></div>
          <div>{t('security.stepTwo.itemTwo')}</div>
        </div>
        { user && user.membership && <>
          <div className='restriction-line'>
            <div><Icon type={IconType.Shield}></Icon></div>
            <div>{t('security.stepTwo.itemThree')}</div>
          </div>
        </>
        }
      </div>
    </div>
  </>
}