import * as ReactDOMClient from 'react-dom/client';
import Confirm from './Confirm';

export class ConfirmManager {
  private containerRef: HTMLDivElement;
  private root;

  constructor() {
    const body = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    const container = document.createElement('div') as HTMLDivElement;
    container.id = 'confirm-box';
    body.insertAdjacentElement('beforeend', container);
    this.containerRef = container;
    this.root = ReactDOMClient.createRoot(this.containerRef);
  }

  public show(title: string, description: string, submit: () => void): void {
    this.root.render(<Confirm title={title} description={description} submit={submit}/>);
  }

  public destroy(): void {
    this.root.render([]);
  }
}

export const confirm = new ConfirmManager();