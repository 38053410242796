import React from 'react';
import { withTranslation } from 'react-i18next';
import Title from '../Title/Title';

class Loading extends React.Component<any> {

  render() {
    const { t } = this.props;

    return <>
      <div className='wrapper-full-page'>
        <Title>{t('loading')}</Title>
      </div>
    </>;
  }
}

export default withTranslation()(Loading);