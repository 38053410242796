import React from 'react';
import { withTranslation } from 'react-i18next';
import AddressForm from './AddressForm';
import './Addresses.scss';
import { AuthContext } from '../../contexts/AuthContext';

class Addresses extends React.Component<any> {

  render() {
    const { t } = this.props;
    const { user } = this.context as any;

    return <>
      <div className='addresses-content'>
        <h1>{t('navigation.addresses')}</h1>
        {user && <AddressForm />}
      </div>
    </>;
  }
}

Addresses.contextType = AuthContext;
export default withTranslation()(Addresses);