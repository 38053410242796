import React from 'react';
import { withTranslation } from 'react-i18next';
import PreferenceForm from './PreferenceForm';
import { AuthContext } from '../../contexts/AuthContext';
import User from '../../models/User';
import Title from '../../components/Title/Title';
import withRouter from '../../withRouter';

class PreferenceCenter extends React.Component<any> {

  render() {
    const { t } = this.props;
    const user = (this.context as any).user as User;

    return <>
      <div className='wrapper-content not-connected'>
        <div className='center-content-size'>
          <h1>{t('prefs.title')}</h1>
          <PreferenceForm user={user} />
        </div>
      </div>
    </>;
  }
}

PreferenceCenter.contextType = AuthContext;
export default withTranslation()(withRouter(PreferenceCenter));