import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonType, Icon, IconType, toast, Error, Popup, popup } from '@om/design-system';
import { AuthContext } from '../../../contexts/AuthContext';
import User from '../../../models/User';
import './DeleteAccount.scss';
import { StepTwo } from './StepTwo';
import { Link, NavLink } from 'react-router-dom';
import { StepOne } from './StepOne';
import { ConfirmPopup } from './ConfirmPopup';

const ID_FORM = "delete-form";

class DeleteAccount extends React.Component<any> {
  state = { step: 1, disabled: true, displayError: false }

  saveDeleteDateToR5 = () => {
    const { token } = this.context as any;

    const data = { customFields: { delete_account_date: new Date().toISOString().split('T')[0] } };
    window.r5.updateProfile({ accessToken: token, data })
      .then(() => {
        window.location.reload();
      })
  }

  askToDelete = () => {
    const { t } = this.props;
    const { user } = this.context as any;

    //send email
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    };
    fetch('/api/user/email', options)
      .then(response => response.json())
      .then(() => {
        this.saveDeleteDateToR5();
      })
      .catch((error: any) => {
        toast.show({ content: t('form.error'), isError: true });
      })
      ;

    this.setState({ valid: true });
  }

  closePopup = () => {
    popup.destroy();
  }
 
  submit = (event: any) => {
    event.preventDefault();
    const { user } = this.context as any;
    this.setState({ displayError: false });

    var form = document.getElementById(ID_FORM) as HTMLFormElement;
		var formData = new FormData(form);
    if (formData.get("email") !== user.email) {
      this.setState({ displayError: true });
    } else {
      popup.showPopup(this.getConfirmPopup(user.email));
    }
  }

  getConfirmPopup = (email: string) => {
    return <>
      <Popup><ConfirmPopup email={email} onClosePopup={this.closePopup} onDeleteAccount={this.askToDelete}></ConfirmPopup></Popup>
    </>;
  }

  onChangeEmail = (e: any) => {
    if (!!e.target.value) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  onChangeState = (step: number) => {
		this.setState({ step: step });
	}

  renderStep() {
    const { t } = this.props;

    switch(this.state.step) {
      case 1:
        return <StepOne></StepOne>;
      case 2:
        return <StepTwo></StepTwo>;
      case 3:
        return <>
          <div>
            <div className='medium subtitle'>{t('security.stepThree.instruction')}</div>
            <form id={ID_FORM} onSubmit={(e) => { e.preventDefault(); }}>
              <div className='form-group'>
                <label htmlFor='email' className='required'>{`${t('security.stepThree.label')}`}</label>
                <input type='text' className='form-control' id='email' name='email' placeholder={`${t('signup.email')}*`} onChange={this.onChangeEmail} required/>
              </div>
            </form>
          </div>
        </>
      default:
        return;
    }
  }

  render() {
    const { t } = this.props;
    const user = (this.context as any).user as User;

    return <>
      <NavLink to="/account/security" className='go-back'>
        <Icon type={IconType.Back}></Icon>
        <div className='first-letter-uppercase'>{t('navigation.security')}</div>
      </NavLink>
      <h1>{t('security.deleteAccount')}</h1>
      <div className="card delete-account">
        {/* Request already in progress */}
        {!!user.deleteAccountDate ? <>
          <div>{t('security.already_requested', { date: user.deleteAccountDate.toLocaleDateString("fr") })}</div>
        
        {/* New request */}
        </> : <>
          {this.renderStep()}
          
          {this.state.step < 3 ? <>
            {/* First steps */}
            <div className='actions'>
              <Button type={ButtonType.Secondary} onClick={() => this.onChangeState(this.state.step + 1)}>{t('security.continue')}</Button>
              <Link to="/account/security" className='button tertiary'>{t('security.cancel')}</Link>
            </div>
         
          </> : <>
            {/* Last step */}
            <div className='last-actions'>
              <Button type={ButtonType.Secondary} id="submit-delete-account" color="#EB4F2A" onClick={this.submit} disabled={this.state.disabled}>{t('security.deleteButton')}</Button>
              <Link to="/account/security"><Button type={ButtonType.Primary} href="/account/security">{t('security.stepThree.cancel')}</Button></Link>
            </div>
            {this.state.displayError &&
              <Error>{t('security.wrong-adress')}</Error>
            }
          </>}
        </>}
      </div>
    </>;
  }
}

DeleteAccount.contextType = AuthContext;
export default withTranslation()(DeleteAccount);