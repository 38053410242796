import React from 'react';
import { withTranslation } from 'react-i18next';
import { getB2B, getOMFR, getRedirect, getTicketing } from '../utils';
import Loading from '../components/Loading/Loading';

enum Website {
    Ticketing = 'Ticketing',
    B2B = 'B2B',
    OmFr = 'OmFr'
}

const EVENT = "om-iframes-logout-loaded";

class Logout extends React.Component<any> {
    completed = 0

    finishLoading = (site: Website) => () => {        
        if (site === Website.Ticketing) { this.completed++; }
        if (site === Website.B2B) { this.completed++; }
        if (site === Website.OmFr) { this.completed++; }
        
        //trigger reachfive logout when all websites responds ok
        if (this.completed === Object.keys(Website).length) {
            // fire event for avoiding interprets call as a frame request
            const event = new CustomEvent(EVENT);
            document.dispatchEvent(event);
        }
    }

    componentDidMount(): void {                
        document.addEventListener(EVENT, () => {
            window.r5.logout({ redirectTo: getRedirect() });
        });
    }

    render() {
        return <>
            <Loading />
            <iframe width={1} height={1} src={`${getTicketing()}/user/logout`} onLoad={this.finishLoading(Website.Ticketing)} onError={this.finishLoading(Website.B2B)} title="Logout Billetterie"></iframe>
            <iframe width={1} height={1} src={`${getOMFR()}/user/logout`} onLoad={this.finishLoading(Website.OmFr)} onError={this.finishLoading(Website.B2B)} title="Logout OM.fr"></iframe>
            <iframe width={1} height={1} src={`${getB2B()}/user/logout`} onLoad={this.finishLoading(Website.B2B)} onError={this.finishLoading(Website.B2B)} title="Logout Business"></iframe>
        </>;
    }
}

export default withTranslation()(Logout);