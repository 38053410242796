import React from 'react';
import { withTranslation } from 'react-i18next';
import PasswordForm from './PasswordForm';
import { Icon, IconType } from '@om/design-system';
import { NavLink } from 'react-router-dom';

class Password extends React.Component<any> {

  render() {
    const { t } = this.props;
    const { user, token } = this.context as any;

    return <>
      <div>
        <NavLink to="/account/security" className='go-back'>
          <Icon type={IconType.Back}></Icon>
          <div className='first-letter-uppercase'>{t('navigation.security')}</div>
        </NavLink>
        <h1>{t('newPassword.modify')}</h1>
        <PasswordForm user={user} token={token} />
      </div>
    </>;
  }
}

export default withTranslation()(Password);