import { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

var uuid = 0;

function PasswordText(props: any) {
	const { placeholder, isLabel = true } = props;
	const [password, setPassword] = useState("");
	const { t } = useTranslation();

	uuid += 1;

	const showPassword = (e: any) => {
		const id = e.target.id.split('-')[1]
		const showButton = document.getElementById(`show-${id}`) as any;
		const hideButton = document.getElementById(`hide-${id}`) as any;
		const el = document.getElementById(`input-${id}`) as any;

		if (el.type === "text") {
			el.type = "password";
			showButton.style.display = 'inline';
			hideButton.style.display = 'none';
		} else {
			el.type = "text";
			showButton.style.display = 'none';
			hideButton.style.display = 'inline';
		}
	}

	const onChange = (event: any) => {
		const password = event.target.value;
		setPassword(password);
		if (props.onChange) {
			props.onChange(event)
		}
	}

	return <div className="form-group width100">
		<label htmlFor="password" style={!isLabel ? { display: 'none' } : {}}>{placeholder}</label>
		<div style={{ position: 'relative' }}>
			<input id={`input-${uuid.toString()}`} type="password" name={props.name || "password"} className="form-control" placeholder={placeholder} onChange={onChange} required></input>
			<span onClick={showPassword}>
				<svg role="img" aria-label={t('form.password.show')} id={`show-${uuid.toString()}`} className="button-icon" style={{ display: "inline" }} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><title>{t("form.password.show")}</title><path id={`show-${uuid.toString()}`} d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-146 0-264-83T40-500q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Zm0-300Zm-.169 240Q601-260 702.5-325.5 804-391 857-500q-53-109-154.331-174.5-101.332-65.5-222.5-65.5Q359-740 257.5-674.5 156-609 102-500q54 109 155.331 174.5 101.332 65.5 222.5 65.5Z" /></svg>
				<svg role="img" aria-label={t('form.password.hide')} id={`hide-${uuid.toString()}`} className="button-icon" style={{ display: "none" }} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><title>{t("form.password.hide")}</title><path id={`hide-${uuid.toString()}`} d="m629-419-44-44q26-71-27-118t-115-24l-44-44q17-11 38-16t43-5q71 0 120.5 49.5T650-500q0 22-5.5 43.5T629-419Zm129 129-40-40q49-36 85.5-80.5T857-500q-50-111-150-175.5T490-740q-42 0-86 8t-69 19l-46-47q35-16 89.5-28T485-800q143 0 261.5 81.5T920-500q-26 64-67 117t-95 93Zm58 226L648-229q-35 14-79 21.5t-89 7.5q-146 0-265-81.5T40-500q20-52 55.5-101.5T182-696L56-822l42-43 757 757-39 44ZM223-654q-37 27-71.5 71T102-500q51 111 153.5 175.5T488-260q33 0 65-4t48-12l-64-64q-11 5-27 7.5t-30 2.5q-70 0-120-49t-50-121q0-15 2.5-30t7.5-27l-97-97Zm305 142Zm-116 58Z" /></svg>
			</span>
		</div>
	</div>;
}

export default withTranslation()(PasswordText);