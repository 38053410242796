import React from 'react';
import { withTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import VerticalToolbar from '../../components/VerticalToolbar/VerticalToolbar';
import './Connect.scss';
import { AuthContext } from '../../contexts/AuthContext';

class ProfilePage extends React.Component<any> {

  render() {
    const { user } = this.context as any;

    return <>
      <div className='wrapper'>
        {user && <>
          <VerticalToolbar />
          <div className='wrapper-content'>
            <Outlet />
          </div>
        </>}
      </div>
    </>;
  }
}

ProfilePage.contextType = AuthContext;
export default withTranslation()(ProfilePage);