import React from 'react';
import { withTranslation } from 'react-i18next';
import PasswordForm from './PasswordForm';
import { Button, ButtonType } from '@om/design-system';

class ForgotPassword extends React.Component<any> {

	render() {
		const { t } = this.props;

		return <>
			<div className='wrapper-content not-connected'>
				<div className="center-content-size">
					<PasswordForm />
					<hr />
					<Button type={ButtonType.Link} href='/' className='center'>{t('backLogin')}</Button>
				</div>
			</div>
		</>
	}
}

export default withTranslation()(ForgotPassword)