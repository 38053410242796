import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { withTranslation } from 'react-i18next';
import './Alert.scss';
import { Button, ButtonType, IconType, TextField, toast } from '@om/design-system';
import { AuthContext } from '../../contexts/AuthContext';
import withRouter from '../../withRouter';
import User from '../../models/User';
import { getOMFR } from '../../utils';
import { loader } from '../../components/Loader/LoaderManager';

class Alert extends React.Component<any> {
  state = {
    phone: null,
    alert: false as any,
    hasParticipated: false,
  }

  onChangePhone = (phone: any) => { this.setState({ phone }); }

  unblacklist = (email: any) => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    };
    fetch('/api/user/unblacklist', options);
  }

  onSubmit = (event?: any) => {
    loader.show();
    event?.preventDefault();
    const { t } = this.props;
    const user = (this.context as any).user as User;

    var formData = new FormData(document.getElementById("form-alert") as HTMLFormElement);
    const data = {
      email: formData.get("email") || user.email,
      ids: [this.state.alert.splio_event_id],
      lang: "fr",
      phone: (this.state.phone || user && user.phoneNumber) || null
    }
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(`/api/user/alerts`, options)
      .then(response => {
        loader.destroy();

        if (!response.ok) {
          throw new Error('Alerts API not reachable');
        }
        return response.json();
      })
      .then(data => {
        this.unblacklist(data.email);

        this.setState({ hasParticipated: true });
        window.location.href = '/alert/validation';
      }).catch((error: any) => {
        loader.destroy();
        toast.show({ content: t('form.error'), isError: true });
      });
  }

  componentDidMount(): void {
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get('redirect');
    const type = params.get('type');

    const url = this.props.matchId ? this.props.matchId : this.props.params.id
    fetch(`${getOMFR()}/api/1.2/promoevents/${url}`)
      .then(res => res.json())
      .then(data => {
        if (data.errorMsg) {
          this.setState({ alert: null });
          return;
        }
        this.setState({ alert: data }, () => {
          // at the end of setState
          const user = (this.context as any).user as User;
          if (type === 'submit' && !!user?.email) {
            this.onSubmit(null);
          }
        });

      }).catch((error: any) => {
        console.log(error)
      });
  }

  render() {
    const { t, matchId } = this.props;
    const { user } = this.context as any;
    var { alert, hasParticipated } = this.state;
    const alerts = user?.alerts || [];
    hasParticipated = hasParticipated || (alert && alerts.includes(alert.splio_event_id));

    return <>
      <div className={!!user ? 'alert-page' : 'alert-page wrapper-content not-connected'}>
        <div id="match-alert-content" className='center-content-size'>
          <Button type={ButtonType.Link} href='/alerts' icon={IconType.Back} >{t('alert_match.seeall')}</Button></div>

          {alert === null && <div>{t('alert_match.not_found')}</div>}

          {!!alert && <>
            <div className='alert-panel card center-content-size'>
              {alert && alert.images[0] ? <>
                <img src={getOMFR() + alert.images[0]} alt="" aria-hidden="true" />
              </> : <>
                <div className='teams-logo'>
                  <img src={getOMFR() + alert.match.home.logo} alt="" role="presentation" />
                  <img src={getOMFR() + alert.match.away.logo} alt="" role="presentation" />
                </div>
              </>}
              <div className='header'>
                <h1>{alert.description}</h1>
                <div className='datetime'>{new Date(alert.date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</div>
                <div className='match-infos'><span>{alert.match.competition.name}</span><span>|</span><span className='location'>{alert.match.venue}</span></div>
              </div>
              <form id="form-alert" className='form-alert' onSubmit={this.onSubmit}>
                {!user && <>
                  <TextField name="email" type="email" placeholder="Adresse email" isLabel={false} required />
                  <PhoneInput id="phone" defaultCountry='FR' className="phone-control" onChange={this.onChangePhone} placeholder="Numéro de téléphone" />
                </>}
                <Button disabled={hasParticipated} type={ButtonType.Submit}>{hasParticipated ? t('alert_match.already') : t('alert_match.alert')}</Button>
              </form>
              {!user &&
                <div>
                  <hr />
                  <div><Button type={ButtonType.Link} href='/' className='center'>{t('login.my_button')}</Button></div>
                </div>
              }
            </div>
          </>}
      </div>
    </>
  }
}

Alert.contextType = AuthContext;
export default withTranslation()(withRouter(Alert));