import { getOMFR } from "../utils";



export default class APIService {
    /**
     * Return alerts from OM API
     * @returns Promise<any>
     */
    static fetchAlerts = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.2/promoevents`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Return player informations from OM API
     * @returns Promise<any>
     */
    static fetchPlayer = async (playerId: String): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.2/player/${playerId}`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    /**
     * Return next match informations from OM API
     * @returns Promise<any>
     */
    static fetchNextMatch = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.2/matchs/next/men`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    /**
     * Return personnalized shirt image with provided name input
     * @param name name to add to shirt
     * @returns 
     */
    static fetchShirt = async (name: String, number: String, type: String): Promise<any> => {
        return new Promise((resolve, reject) => {
            const url = `/api/user/shirt?name=${name}&number=${number}&type=${type}`;
            fetch(url)
                .then(res => res.blob())
                .then(data => {
                    resolve(URL.createObjectURL(data)); 
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    /**
     * Return profile info
     * @param id r5 profile id
     * @returns 
     */
    static fetchOMProfile = async (id: String): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.0/user/${id}`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }
}