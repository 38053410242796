import { useEffect } from 'react';
import QrScanner from 'qr-scanner';
import { Popup, popup } from '@om/design-system';
import UserValidationPopup from './UserValidationPopup';

const Scan = (props: any) => {
  var qrScanner: any;

  useEffect(() => {
    qrScanner = new QrScanner(
      document.getElementById('om-qrcode') as HTMLVideoElement,
      scan,
      { /* your options or returnDetailedScanResult: true if you're not specifying any other options */ },
    );
    qrScanner.start()
      .then(() => { changeFrameVisible(true) })
      .catch((err: any) => console.log(err));
  });

  const scan = (res: any) => {    
    if (!!res.data) {
      const id = res.data;
      qrScanner.stop();
      changeFrameVisible(false)
      popup.showPopup(<Popup><UserValidationPopup id={id} closePopup={closePopup}/></Popup>)
    } 
  }

  const closePopup = (): void => {
    popup.destroy();
    qrScanner.start()
      .then(() => { changeFrameVisible(true) })
      .catch((err: any) => console.log(err));
  }

  const changeFrameVisible = (setVisible: boolean): void => {
    if (setVisible) {
      document.getElementById("camera-frame")!.style.visibility = "visible";
      document.getElementById("camera-border")!.style.visibility = "visible";
      document.getElementById("video-text")!.style.visibility = "visible";
    } else {
      document.getElementById("camera-frame")!.style.visibility = "hidden";
      document.getElementById("camera-border")!.style.visibility = "hidden";
      document.getElementById("video-text")!.style.visibility = "hidden";
    }
  }

  return <>
    <div className='scan-container'>
      <div className="video-wrapper">
        <video id='om-qrcode' className='qr-code'></video>
        <video id='camera-frame' className='video-frame'></video>
        <video id='camera-border' className='video-frame-background'></video>
        <div id='video-text' className='video-text'>Scanner le QR Code</div>
      </div>
    </div>
  </>
}

export default Scan;
