import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast, ForgottenPasswordForm, Button, ButtonType } from '@om/design-system';
import { getResetPassword, getRedirect, getRedirectUri } from '../../utils';
import { AuthContext } from '../../contexts/AuthContext';

class PasswordForm extends React.Component<any> {

  names = {
    forgot_form: 'forgot-form', 
    email: 'email'
  }

  state = {
    magicLink: false
  }

  submit = (event: any) => {
    event.preventDefault();
    const { t } = this.props;
    const { user } = this.context as any;

    const form = document.getElementById(this.names.forgot_form) as HTMLFormElement;
    const formData = new FormData(form);
    const email: any = formData.get("email") || user.email;

    if (!email) {
      toast.show({ content: t('form.error'), isError: true });
      return;
    }
    
    window.r5.requestPasswordReset({ email, redirectUrl: getResetPassword() })
      .then(() => {
        const email = (document.getElementById('email') as HTMLInputElement);
        if (email) {
          email.value = '';
        }
        toast.show({ content: t('form.success') });
        setTimeout(function () {
					window.r5.logout({ redirectTo: getRedirect() });
				}, 3000);
      })
      .catch((error: any) => {
        toast.show({ content: error?.errorUserMsg || t('form.error'), isError: true });
      });
  }

  clickMagicLink = () => {
    const { magicLink } = this.state;
    const { t } = this.props;
    const form = document.getElementById(this.names.forgot_form) as HTMLFormElement;
    const formData = new FormData(form);
    const email = formData.get(this.names.email);

    if (!email) {
      form.reportValidity();
      return;
    }

    // if magic link has already been called
    if (magicLink) {
      toast.show({ content: t('magic_link.wait'), isError: true });
      return;
    }
    this.setState({ magicLink: true });

    window.r5.startPasswordless({ email, authType: 'magic_link' }, { redirectUri: getRedirectUri() })
      .then((res: any) => {
        toast.show({ content: t('magic_link.success_email'), timeout: 10000 });
      })
      .catch((error: any) => {
        toast.show({ content: error.errorUserMsg || t('form.error'), isError: true, timeout: 10000 });
      });
  }

  render() {
    const { t } = this.props;
    const { user } = this.context as any;

    if (user && user.email) {
      return <div className="left-content-size card">
        <form id={this.names.forgot_form} onSubmit={this.submit} className='form'>
          <label>{t('newPassword.withEmail', { email: user.email })}</label>
          <Button type={ButtonType.Submit}>{t('validate')}</Button>
        </form>
      </div>
    }

    return <ForgottenPasswordForm onSubmit={this.submit} onClickMagicLink={this.clickMagicLink} names={this.names} isLabel={false} />
  }
}

PasswordForm.contextType = AuthContext;
export default withTranslation()(PasswordForm)