import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, ButtonType } from '@om/design-system';

function ResetEmail() {
    // const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const [user, setUser] = useState(undefined as any);

    return <>
      <div className={!!user ? 'wrapper-content' : 'wrapper-content not-connected'}>
        <div className="center-content-size center">
          <div className="form">
            <h1>{t('newPassword.reset')}</h1>
            <div className="alert-dismissible fade show alert alert-success" role="alert">{t('form.success')}</div>
            <Button type={ButtonType.Link} href='/' className='center'>{t('backLogin')}</Button>
          </div>
        </div>
      </div>
    </>;
}

export default withTranslation()(ResetEmail);