import { Button, ButtonType, Icon, IconType } from '@om/design-system';
import React from 'react';
import { withTranslation } from 'react-i18next';

class SubmitValidation extends React.Component<any> {

  render() {
    const { t } = this.props;

    return <>
      <div className='validation-alert wrapper-content not-connected'>
        <div className='center-content-size center card'>
          <div><img src="/logo-om.png" width="48" alt="Logo Olympique de Marseille" /></div>
          <div><Icon type={IconType.Success} size={72} /></div>
          <div className='title'>{t('alert_match_validation.thanks')}</div>
          <div>{t('alert_match_validation.done')}</div>
          <div><Button type={ButtonType.Link} href='/alerts' className='center'>{t('alert_match.seeall')}</Button></div>
          <div className='footer'>
            <div className='first-border' />
            <div className='author'>{t('om')}</div>
            <div className='second-border' />
          </div>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(SubmitValidation);