import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import '@om/design-system/dist/index.css';

import reportWebVitals from './reportWebVitals';

import './i18n';

createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window {
    r5: any;
    dqe: any;
  }
}