import React from 'react';
import { withTranslation } from 'react-i18next';
import './Shortcut.scss';
import { AuthContext } from '../../contexts/AuthContext';
import { Icon } from '@om/design-system';

class Shortcut extends React.Component<any> {
  render() {
    const { href, icon, isTargetblank = true, children } = this.props;

    return <>
      <a href={href} target={ isTargetblank ? '_blank' : ''}>
        <div className='card shortcut'>
          <Icon type={icon} fill="#0098D8"></Icon>
          <div>{children}</div>
        </div>
      </a>
    </>;
  }
}

Shortcut.contextType = AuthContext;
export default withTranslation()(Shortcut);