import { useContext, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import './Business.scss';
import Title from '../../components/Title/Title';
import { Button, ButtonType, Icon, IconType, toast } from '@om/design-system';
import { AuthContext } from '../../contexts/AuthContext';
import Loading from '../../components/Loading/Loading';
import { Membership_Type } from '../../models/Membership';

const UserValidationPopup = (props: any) => {
  const { token } = useContext(AuthContext);
  const [user, setUser] = useState(undefined as any);
  const { t } = useTranslation();
  const { id, closePopup } = props;

  const getUserStatus = async (id: String) => {
    return new Promise((resolve, reject) => {
      const options: any = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token }
      };
      
      fetch(`/api/membership/${id}`, options)
        .then((result: any) => {
          return result.json();
        })
        .then((json: any) => {
          if (!!json?.id) {
            resolve(json);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  useEffect(() => {
    if (!id) {
      toast.show({ content: t('form.error'), isError: true });
      return;
    }
    
    getUserStatus(id)
      .then((res: any) => { setUser(res) })
      .catch(() => {setUser({})})
  }, []);

  return <>
    <div className='text-center business'>
      <div>
        <div className="login_close" onClick={closePopup}>
          <Icon type={IconType.Close} size={26} />
        </div>

        {user === undefined ? <>
          <Loading />
        </> : <>
          <div className='account-valid-icon'>
            {!user?.is_member &&
              <svg className='invalid' xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="80" preserveAspectRatio='xMidYMid meet' aria-hidden role="presentation"><path d="m330-288 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/></svg>
            }
            {user?.is_member &&
              <svg className='valid' xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="80" preserveAspectRatio='xMidYMid meet' aria-hidden role="presentation"><path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>
            }
            <div className={user?.is_member ? "valid" : "invalid"}>{user?.is_member ? t('business.valid') : t('business.invalid')}</div>
          </div>
          <div>
            <h1><div className='account-name'>{user?.email}</div></h1>
            {user?.is_member ? <>
              <Title>{user?.membership?.type === Membership_Type.om_legendes ? t('business.program_member_om_legendes') : t('business.program_member_pbb')}</Title> 
            </> : <>
              <Title>{t('business.user_not_member')}</Title> 
            </>}
          </div>
        </>}
      </div>
      <Button type={ButtonType.Primary} onClick={closePopup}>Scanner à nouveau</Button>
    </div>
  </>;
}

UserValidationPopup.contextType = AuthContext;
export default withTranslation()(UserValidationPopup);

