import React from 'react';
import { withTranslation } from 'react-i18next';
import './Business.scss';
import Title from '../../components/Title/Title';

class ClubMemberAccount extends React.Component<any> {

  render() {
    const { t, user } = this.props;

    return <>
      <div className='padding-xlarge text-center business'>
        <div>
          <svg className='valid' xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="80" preserveAspectRatio='xMidYMid meet' aria-hidden role="presentation"><path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>
          <div className='valid'>{t('business.valid')}</div>
        </div>
        <div>
          <h1><div className='account-name'>{user.firstName} {user.lastName}</div></h1>
          <Title>{t('business.program_member')}</Title>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(ClubMemberAccount);