import React from "react";
import { withTranslation } from "react-i18next";
import './Checkbox.css';

class Checkbox extends React.Component<any> {

    render() {
        const { id, defaultChecked, onChange } = this.props;

        return <span>
            <input type="checkbox" className="omCheckbox" id={id} defaultChecked={defaultChecked} name={id} key={Math.random()} onChange={onChange} />
            <label className="omCheckboxLabel" htmlFor={id}>
                <div className="tick_mark"></div>
            </label>
        </span>;

    }

}

export default withTranslation()(Checkbox);