import React from 'react';
import { withTranslation } from 'react-i18next';
import { deleteCookie, getCookie, getRedirect, getRedirectUri } from '../../utils';
import './login.scss';
import { toast, LoginForm } from '@om/design-system';

const ID_FORM = "signin-form";

class Login extends React.Component<any> {
  id = 0;

  state = {
    magicLink: false,
    captcha: undefined
  }

  submit = (event: any) => {
    event.preventDefault();
    const form = document.getElementById(ID_FORM) as HTMLFormElement;
    const formData = new FormData(form);
    const { t } = this.props

    const email = formData.get("email");
    const password = formData.get("password");    

    window.r5.loginWithPassword({
      email,
      password,
      // captchaToken: this.state.captcha,
      auth: {
        responseType: 'code',
        persistent: true,
        useWebMessage: false,//enable automatic redirect
        redirectUri: getRedirectUri(),
      }
    })
      .then((res: any) => {
        // if auto redirect is disable
        //window.location.replace(getRedirect());
      })
      .catch((error: any) => {        
        toast.show({ content: error.errorUserMsg || t('form.error'), isError: true });
      });
  }

  loginWithProviders = (social: string) => {
    window.r5.loginWithSocialProvider(social, {
      redirectUri: getRedirectUri(),
      popupMode: true
    })
  }

  clickMagicLink = () => {

    const { magicLink } = this.state;
    const { t } = this.props;

    const form = document.getElementById(ID_FORM) as HTMLFormElement;
    const formData = new FormData(form);

    const email = formData.get("email");

    if (!email) {
      form.reportValidity();
      return;
    }

    // if magic link has already been called
    if (magicLink) {
      toast.show({ content: t('magic_link.wait'), isError: true });
      return;
    }
    this.setState({ magicLink: true });

    window.r5.startPasswordless({ email, authType: 'magic_link' }, { redirectUri: getRedirect() })
      .then((res: any) => {
        toast.show({ content: t('magic_link.success_email'), timeout: 10000 });
      })
      .catch((error: any) => {
        toast.show({ content: error.errorUserMsg || t('form.error'), isError: true, timeout: 10000 });
      });
  }

  componentDidMount(): void {
    const redirect = getCookie('loginas');
    if (!!redirect) {
      deleteCookie('loginas')
      window.location.replace(redirect);
    }
  }

  render() {
    const forgotURL = `/forgot-password${window.location.search}`;
    const signupURL = `/signup${window.location.search}`;

    return (
      <div className='login-content'>
        <div className='scroll-content'>
          <div className='form'>
            <img className='logo-om' src="logo-om.png" alt="" aria-hidden="true" />
            <LoginForm onSubmit={this.submit} hrefPassword={forgotURL} hrefCreateAccount={signupURL} names={{login_form: 'signin-form', email: 'email', password: 'password'}} isLabel={false} />
            {/* <div id="magic-link" className='alert-dismissible fade show alert alert-secondary' role="alert" onClick={this.clickMagicLink}>
              <Icon type={IconType.Wand} />
              <label>{t('magic_link.description')}</label>
            </div> */}
            <div className='partners'>
              <a target="_blank" rel="noreferrer" href="https://om.fr">
                <img className='omc-logo' src="https://www.om.fr/sites/default/files/2019-06/puma-logo%403x.png" />
              </a>
              <a target="_blank" rel="noreferrer" href="https://cmacgm-group.com">
                <img className='omc-logo' src="https://www.om.fr/sites/default/files/2023-07/cmacgm-logo.png" />
              </a>
            </div>
          </div>
        </div>

        <div className='image-content'>
          <div><img src='login-stade.jpg' alt="" aria-hidden="true" /></div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login)