import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const Loader: React.FC<any> = (props) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div className='content'>
      <div className='spinner' />
    </div>
  );
}

export default withTranslation()(Loader);
