import { Navigate } from "react-router-dom";
import { getHome, getLogin } from "../utils";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const AuthRoute = (props: any) => {
  const { children } = props;
  const { logged: isAuth, user } = useContext(AuthContext);

  const query = window.location.search || "";
  const restricted_url = window.location.pathname.includes("admin");

  if (restricted_url && user?.isAdmin !== true) {
    return isAuth === true ?
      <Navigate to={getHome() + query} replace /> :
      <Navigate to={getLogin() + query} replace />
      ;
  }

  // IF user IS NOT connected
  // THEN redirect to login
  if (isAuth === false) {
    return <Navigate to={getLogin() + query} replace />;
  }
  // IF user IS connected and try to access login
  // THEN redirect to account profile
  if (isAuth === true && ['/login', '/'].includes(window.location.pathname)) {
    return <Navigate to={getHome() + query} replace />;
  }
  
  // prevent access profile if not required
  if (isAuth === true && user?.hasMissingFields() !== true && window.location.pathname === "/profile") {    
    return <Navigate to={getHome()} replace />;
  } else if (isAuth === true && user?.hasMissingFields() === true && window.location.pathname !== "/profile") {        
    return <Navigate to='/profile' replace />;
  }

  return children;
}