import React from "react";
import { withTranslation } from "react-i18next";
import dqe_single from "../../assets/js/dqe.js";
import '../../assets/styles/dqe-autocomplete.css';
import { Button, ButtonType, toast } from '@om/design-system';
import { AuthContext } from "../../contexts/AuthContext";
import Address from "../../models/Address";

const ID_FORM = "address-form'";

class AddressForm extends React.Component<any> {
  state = {
    street: undefined,
    complement: undefined,
    code: undefined,
    city: undefined,
  }

  submit = (event: any) => {
    event.preventDefault();
    const form = document.getElementById(ID_FORM) as HTMLFormElement;
    const formData = new FormData(form);
    const { t } = this.props;
    const { user, token } = this.context as any;

    user.address = Address.mapFromReachfive({
      default: true,
      country: formData.get("country"),
      streetAddress: formData.get("address"),
      locality: formData.get("city"),
      addressComplement: formData.get("address_compl") || undefined,
      postalCode: formData.get("postal_code"),
    });

    window.r5.updateProfile({ accessToken: token, data: { addresses: [user.address] } })
      .then(() => {
        toast.show({ content: t('form.success') });
        window.location.reload();// force address bar to disappear
      })
      .catch((error: any) => {
        toast.show({ content: t('form.error'), isError: true });
      })
  }

  formatText = (name: any) => (e: any) => {
    const value = e.target.value.toUpperCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, '');
    this.setState({ ...this.state, [name]: value });
  }

  formatCity = (name: any) => (e: any) => {
    const value = e.target.value.toUpperCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 \(\)]/g, '');
    this.setState({ ...this.state, [name]: value });
  }

  componentDidMount(): void {
    try {
      window.dqe = new dqe_single({
        server: '/api/dqe',
        license: '',
        country: '#country',
        single: '#address',
        zip: '#postal_code',
        city: '#city',
        prov: '',
        street: '#address',
        compl: '#address_compl',
        local: '',
        company: '',
        geoloc: '',
        restricted: true,
        taille: 38
      });
    } catch (e) {
      console.error('Error while setting up address module ', e);
    }
  }

  render() {
    const { t } = this.props;
    const { street, complement, code, city } = this.state;
    const { user } = this.context as any;
    const address = user.address;

    return <div className="left-content-size">
      <form id={ID_FORM} onSubmit={this.submit} className="form">
        <div className="form-group">
          <label htmlFor="country" className='required'>{t('address.country')}</label>
          <select id="country" name="country" defaultValue={address.country} required >
            <option value="FRA">France</option>
            <option value="ZAF">Afrique du Sud</option>
            <option value="DZA">Algérie</option>
            <option value="DEU">Allemagne</option>
            <option value="SAU">Arabie Saoudite</option>
            <option value="ARG">Argentine</option>
            <option value="AUS">Australie</option>
            <option value="AUT">Autriche</option>
            <option value="BEL">Belgique</option>
            <option value="BRA">Brésil</option>
            <option value="BHR">Bahreïn</option>
            <option value="CAN">Canada</option>
            <option value="CHN">Chine</option>
            <option value="KOR">Corée du Sud</option>
            <option value="CIV">Côte d'Ivoire</option>
            <option value="HRV">Croatie</option>
            <option value="DNK">Danemark</option>
            <option value="ARE">Émirats arabes unis</option>
            <option value="ESP">Espagne</option>
            <option value="USA">Etats-Unis</option>
            <option value="FIN">Finlande</option>
            <option value="GRC">Grèce</option>
            <option value="HKG">Hong-Kong</option>
            <option value="HUN">Hongrie</option>
            <option value="ISR">Israël</option>
            <option value="ITA">Italie</option>
            <option value="IRL">Irlande</option>
            <option value="JPN">Japon</option>
            <option value="KWT">Koweït</option>
            <option value="LUX">Luxembourg</option>
            <option value="MAR">Maroc</option>
            <option value="MEX">Mexique</option>
            <option value="NOR">Norvège</option>
            <option value="NZL">Nouvelle-Zélande</option>
            <option value="NLD">Pays-Bas</option>
            <option value="POL">Pologne</option>
            <option value="PRT">Portugal</option>
            <option value="QAT">Qatar</option>
            <option value="CZE">République tchèque</option>
            <option value="ROU">Roumanie</option>
            <option value="GBR">Royaume-Uni</option>
            <option value="RUS">Russie</option>
            <option value="SGP">Singapour</option>
            <option value="SVN">Slovénie</option>
            <option value="SVK">Slovaquie</option>
            <option value="SWE">Suède</option>
            <option value="CHE">Suisse</option>
            <option value="TWN">Taiwan</option>
            <option value="THA">Thaïlande</option>
            <option value="TUN">Tunisie</option>
            <option value="TUR">Turquie</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="address" className='required'>{`${t('address.address')}`}</label>
          <input
            type="text" className="form-control" id="address" name="address" autoComplete="off" required
            placeholder={t('address.address')} value={street} defaultValue={address.streetAddress} maxLength={38} onChange={this.formatText('street')} />
        </div>

        <div className="form-group">
          <label htmlFor="address_compl">{t('address.compl')}</label>
          <input
            type="text" className="form-control" id="address_compl" name="address_compl" autoComplete="off"
            placeholder={t('address.compl')} value={complement} defaultValue={address.addressComplement} maxLength={38} onChange={this.formatText('complement')} />
        </div>

        <div className="form-group">
          <label htmlFor="postal_code" className='required'>{`${t('address.postal')}`}</label>
          <input
            type="text" className="form-control" id="postal_code" name="postal_code" autoComplete="off" required
            placeholder={t('address.postal')} value={code} defaultValue={address.postalCode} maxLength={38} onChange={this.formatText('code')} />
        </div>

        <div className="form-group">
          <label htmlFor="city" className='required'>{`${t('address.city')}`}</label>
          <input
            type="text" className="form-control" id="city" name="city" autoComplete="off" required
            placeholder={t('address.city')} value={city} defaultValue={address.locality} maxLength={38} onChange={this.formatCity('city')} />
        </div>

        <Button type={ButtonType.Submit}>{t('validate')}</Button>
      </form>
    </div>
  }
}

AddressForm.contextType = AuthContext;
export default withTranslation()(AddressForm);