import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { withTranslation } from 'react-i18next';
import './AlertsList.scss';
import { AuthContext, AuthSession } from '../../contexts/AuthContext';
import Switch from '../../components/Switch';
import { Button, ButtonType, Icon, IconType, TextField, toast } from '@om/design-system';
import { MatchList } from './MatchList';
import { ConsentName } from '../../models/Consent';
import { loader } from '../../components/Loader/LoaderManager';
import User from '../../models/User';
import APIService from '../../services/APIService';

class AlertsList extends React.Component<any> {
  state = {
    phone: null,
    checkCount: 0,
    alerts: null as any
  }

  onChangePhone = (phone: any) => {
    this.setState({ phone: phone });
  }

  unblacklist = (email: any) => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    };
    fetch('/api/user/unblacklist', options)
      .catch((error: any) => {
        console.log(error);
      });
  }

  submitNotLogged = (event: any) => {
    loader.show();
    event.preventDefault();

    const user = (this.context as any).user;

    // Update matches alerts API
    var formData = new FormData(document.getElementById("form-alerts") as HTMLFormElement);
    const data = {
      email: formData.get("email") || user.email,
      ids: formData.getAll("match"),
      lang: "fr",
      phone: (this.state.phone || user && user.phoneNumber) || null
    }
    this.submitAlert(data);
  }

  submitLogged = (event: any) => {
    loader.show();
    const { t } = this.props;
    const user = (this.context as any).user;
    if (!user) {
      toast.show({ content: t('form.no_user'), isError: true });
      return
    }

    const data = {
      email: user.email,
      ids: [event.target.id],
      lang: "fr",
      phone: user.phoneNumber || null
    }
    this.submitAlert(data);
  }

  submitAlert = (data: any) => {
    const { t } = this.props;

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(`/api/user/alerts`, options)
      .then(response => {
        loader.destroy();
        if (!response.ok) {
          throw new Error('Alerts API not reachable');
        }
        return response.json();
      })
      .then(response => {
        this.unblacklist(data.email);        

        const context = this.context as AuthSession;
        const user = context.user as User;
        // handle no connected user
        if (user?.id) {
          user.alerts = [...user.alerts || [], ...data.ids]
          context.setSession({ ...context, user });
        }

        toast.show({ content: t('alert_match.success'), isError: false });
      }).catch((error: any) => {
        console.error(error);
        loader.destroy();
        toast.show({ content: t('form.error'), isError: true });
      });
  }

  updateTicketingPreference = () => {
    const { t } = this.props;
    const user = (this.context as any).user;

    const consents = user && user.getConsentsAsDict();
    const ticketing = user && consents[ConsentName.ticketing].granted;
    if (!ticketing) {
      const dataPref = {
        id: user.id,
        email: user.email,
        date: new Date().toISOString(),
        news: null,
        ticketing: (document.getElementById("preferences-billetterie")! as HTMLInputElement).checked,
        eshop: null,
        partners: null,
      };
      const optionsPref = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataPref)
      };
      fetch('/api/preferences', optionsPref)
        .then(response => {
          if (!response.ok) {
            throw new Error('Preferences API not reachable');
          }
          toast.show({ content: t('form.success'), isError: false });
          return response.json()
        })
        .catch((error: any) => {
          toast.show({ content: t('form.error'), isError: true });
        });
    }
  }

  componentDidMount(): void {
    APIService.fetchAlerts()
      .then((data) => {
        this.setState({ alerts: data })
      })
      .catch((error) => {
        console.log(error)
      })
    ;
  }

  switchCheckedCount = (e: any) => {
    if (e.checked) {
      this.setState({ checkCount: this.state.checkCount + 1 });
    } else {
      this.setState({ checkCount: this.state.checkCount - 1 });
    }
  }

  render() {
    const { t } = this.props;
    const user = (this.context as any).user;
    const { alerts } = this.state;
    const { checkCount } = this.state;
    const consents = user && user.getConsentsAsDict();
    const ticketing = user && consents[ConsentName.ticketing].granted;

    return <>
      <div className={!!user ? '' : 'wrapper-content not-connected'}>
        <div className={!!user ? 'alerts-list' : 'alerts-list'}>
          {!!user &&
            <div className='see-all'><Button type={ButtonType.Link} href='/' icon={IconType.Back} >{t('my_dashboard')}</Button></div>
          }
          <h1>{t('alert_match.title')}</h1>
          <div>{t('alert_match.description')}</div>

          <form id="form-alerts" className='form-alert' onSubmit={this.submitNotLogged}>
            {/** NO ALERT FETCHED */}
            {alerts?.length === 0 && <>
              <div>{t('alert_match.no_alert')}</div>
            </>
            }

            {/** SOME ALERTS FETCHED TO DISPLAY */}
            {alerts?.length > 0 && <>
              <MatchList alerts={alerts} user={user} onSwitch={(e: any) => this.switchCheckedCount(e.target)} onClick={this.submitLogged} />
              {!user && <>
                <div className='email-and-phone'>
                  <div className="input-size"><TextField name="email" type="email" placeholder="Adresse email" isLabel={false} required /></div>
                  <PhoneInput id="phone" defaultCountry='FR' className="phone-control" onChange={this.onChangePhone} placeholder="Numéro de téléphone" />
                </div>
              </>}
            </>
            }

            {/* For logged users only, shortcut for ticketing preference only if preference is false */}
            {!!user && !ticketing && <>
              <div className="card asking-preference">
                <Icon type={IconType.Bell} fill={"#0098D8"} size={32} />
                <div className='text'>
                  <div className='title'>{t("alert_match.optin_billetterie")}</div>
                  <div className='content'>{t("alert_match.optin_text")}</div>
                </div>
                <Switch id={"preferences-billetterie"} name={"preferences-billetterie"} onSwitch={this.updateTicketingPreference} />
              </div>
            </>}

            {!user &&
              <div className='submit-action'><Button type={ButtonType.Submit} disabled={checkCount === 0}>{t('validate')}</Button></div>
            }
          </form>
        </div>
      </div>
    </>
  }
}

AlertsList.contextType = AuthContext;
export default withTranslation()(AlertsList);