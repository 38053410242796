import { useContext, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PasswordText from "../../components/PasswordText";
import { Button, ButtonType, toast } from '@om/design-system';
import { AuthContext, AuthSession } from "../../contexts/AuthContext";

function AdminUserPassword(props: any) {
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [password2, setPassword2] = useState("");
	const [valid, setValid] = useState(false);
	const { t } = useTranslation();
	const { token } = useContext(AuthContext) as AuthSession;

	var valideR1 = false, valideR2 = false, valideR3 = false, valideR4 = false, valideR5 = false
	const minChar = /^.{8,}$/;
	const upperCase = /[A-Z]/g;
	const numbers = /[0-9]/g;
	const specialChar = /[*.!@#$%^&(){}[\]:;<>,.?\/~_+\-=|]/

	const submit = (event: any) => {
		event.preventDefault();
		setLoading(true);

		if (!valideR5 && !!password) {
			toast.show({ content: t("newPassword.notSame"), isError: true });
			setLoading(false);
			return;
		}

		if (!valideR2 || !valideR3 || !valideR4) {
			toast.show({ content: t("newPassword.security"), isError: true });
			setLoading(false);
			return;
		}
		
		//send email
		const options = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'x-token': token! },
			body: JSON.stringify({ id: props.id, password: password })
		};
		fetch('/api/user/password', options)
			.then(() => {
				setLoading(false);
				toast.show({ content: t('form.success') });
			})
			.catch((error: any) => {
				setLoading(false);
				toast.show({ content: t('form.error'), isError: true });
			})
			;
	}

	const onChangePassword = (event: any) => {
		setPassword(event.target.value);
	}

	const onChangePassword2 = (event: any) => {
		setPassword2(event.target.value);
	}

	if (password.match(minChar)) {
		valideR1 = true
	}
	if (password.match(numbers)) {
		valideR2 = true
	}
	if (password.match(upperCase)) {
		valideR3 = true
	}
	if (password.match(specialChar)) {
		valideR4 = true
	}
	if (password.match(password2)) {
		valideR5 = true
	}

	return <div className="left-content-size">
		<form className="form">
			<label>{t('admin_user.password_confirm', { email: props.email })}</label>
			{!valid && <>
				<PasswordText name="password" placeholder={t('newPassword.placeholder')} onChange={onChangePassword} isLabel={false} />
				<div className='x-small'>
					<div>{t('newPassword.rule')}</div>
					<div className='password-rules'>
						<div className={valideR1 ? 'rule-valide' : ''}>* {t('newPassword.ruleOne')}</div>
						<div className={valideR2 ? 'rule-valide' : ''}>* {t('newPassword.ruleTwo')}</div>
						<div className={valideR3 ? 'rule-valide' : ''}>* {t('newPassword.ruleThree')}</div>
						<div className={valideR4 ? 'rule-valide' : ''}>* {t('newPassword.ruleFour')}</div>
					</div>
				</div>

				<PasswordText name="password2" placeholder={t('newPassword.placeholder2')} onChange={onChangePassword2} isLabel={false} />
				<Button type={ButtonType.Primary} disabled={loading} onClick={submit}>{t('validate')}</Button>
				<Button type={ButtonType.Secondary} disabled={loading} onClick={props.onCancel}>{t('cancel')}</Button>
			</>}
		</form>;
	</div>
}

AdminUserPassword.contextType = AuthContext;
export default withTranslation()(AdminUserPassword);