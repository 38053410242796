import { useTranslation, withTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";
import { getBase, getHome, getLogin } from "../utils";
import Loading from "../components/Loading/Loading";
import { Button, ButtonType } from '@om/design-system';

// redirect page for:
// - new email confirmation
// - account creation confirmation
// - update password
// - update profile (password + profile general)
function Redirect(props: any) {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const code = searchParams.get('code');
    const error = {
        type: searchParams.get('error'),
        description: searchParams.get('error_description'),
    };
    const redirect = searchParams.get('redirect');
    // use for custom redirect 
    if (!!redirect) {
        return <Navigate to={redirect} replace />;
    }

    if (!!error.type) {
        return <div className="form">
            <h1>{t('confirmation')}</h1>
            <div className="alert-dismissible fade show alert alert-danger" role="alert">{error.description}</div>
            <Button type={ButtonType.Link} href='/' className='center'>{t('navigation.back')}</Button>
        </div>;
    }

    // if connected ok
    if (!!code) {
        const state = searchParams.get('state');
        const url = !!state ? state : getBase() + getHome();
        window.location.replace(url);
        return <Loading />;
    }
    // if not connected redirect to login
    return <Navigate to={getLogin()} replace />;
}

export default withTranslation()(Redirect);