import React from 'react';
import { withTranslation } from 'react-i18next';
import './Security.scss';
import { Link } from 'react-router-dom';

class Security extends React.Component<any> {

  render() {
    const { t } = this.props;

    return <>
      <h1>{t('navigation.security')}</h1>

      <div className='security'>
        {/* Change Password */}
        <div className='card'>
          <div className='header'>{t('password')}</div>
          <div className='card-content'>
            <div className='content'>{t('security.changePassword')}</div>
            <Link className='button tertiary' to="/account/security/password">{t('security.modify')}</Link>
          </div>
        </div>

        {/* Delete Account */}
        <div className='card'>
          <div className='header'>{t('security.deleteAccount')}</div>
          <div className='card-content'>
            <div className='content'>{t('security.deleteAccountDetail')}</div>
            <Link className='button tertiary' to="/account/security/delete-account">{t('security.delete')}</Link>
          </div>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(Security);