import React from 'react';
import { withTranslation } from 'react-i18next';
import './Card.scss';
import { AuthContext } from '../../contexts/AuthContext';
import card_cbb from '../../assets/img/cbb/member_card_cbb.png';
import card_virage from '../../assets/img/cbb/member_card_virage.png';
import card_legendes from '../../assets/img/cbb/member_card_omLegendes.png';
import { Membership_Type } from '../../models/Membership';

class Card extends React.Component<any> {
  render() {
    const { t, membership } = this.props;
    const { user } = this.context as any;

    if (!membership) { return <></> }

    return <>
      <div className='card-component'>

        {/* TODO */}
        {membership.type === Membership_Type.membership_virage &&
          <img className='member-card' src={card_virage} alt={t('dashboard.alt_virage_card')} />
        }
        {membership.type === Membership_Type.full_membership &&
          <img className={membership ? 'member-card' : 'member-card not-member'} src={card_cbb} alt={t('dashboard.alt_cbb_card')} />
        }
        {membership.type === Membership_Type.om_legendes &&
          <img className={membership ? 'member-card' : 'member-card not-member'} src={card_legendes} alt={t('dashboard.alt_cbb_card')} />
        }
        <div className={membership.type === Membership_Type.full_membership ? 'member-card-info' : 'member-card-info dark'}>
          <div className='name'><span className='lastname'>{user.lastName}</span>&nbsp;<span>{user.firstName}</span></div>
          <div>{membership.id}</div>
          <div>{t('dashboard.since')} {membership.startDate.toLocaleDateString('fr-FR')}</div>
        </div>
      </div>
    </>;
  }
}

Card.contextType = AuthContext;
export default withTranslation()(Card);