import * as ReactDOMClient from 'react-dom/client';
import Loader from './Loader';

export class LoaderManager {
  private containerRef: HTMLDivElement;
  private root;

  constructor() {
    const body = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    const loaderContainer = document.createElement('div') as HTMLDivElement;
    loaderContainer.id = 'loading-spinner';
    body.insertAdjacentElement('beforeend', loaderContainer);
    this.containerRef = loaderContainer;
    this.root = ReactDOMClient.createRoot(this.containerRef);
  }

  public show(): void {
    this.root.render(<Loader />);
  }

  public destroy(): void {
    this.root.render([]);
  }
}

export const loader = new LoaderManager();