import React from 'react';
import { withTranslation } from 'react-i18next';
import './PartnerBloc.scss';
import { Button, ButtonType } from '@om/design-system';

class PartnerBloc extends React.Component<any> {
  render() {
    const { t, isPBB, link, header, children } = this.props;

    return <>
      <div className="partner">
        <div className={'card'} onClick={(e) => { e.preventDefault(); window.open(link, '_blank', 'noreferrer') }}>
          <div>
            {isPBB === true &&
              <div className="tag">{t('dashboard.exclusivity-pbb')}</div>
            }
            <div>{header}</div>
            <div>{children}</div>
          </div>
          <Button type={ButtonType.Link} href={link} className='desktop' isTargetblank={true}>{t('business.use_offer')}</Button>
        </div>
      </div>
    </>;
  }
}

export default withTranslation()(PartnerBloc);