export enum ConsentName {
    news = "om_actu_club",
    ticketing = "om_billetterie",
    eshop = "om_boutique",
    partners = "om_partenaires",
    fondation = "om_fondation",
}

enum ConsentType {
    optin = "opt-in",
    optout = "opt-out"
}

class Consent {
    name: ConsentName;
    granted: boolean = false;
    type: ConsentType = ConsentType.optin;
    date: Date = new Date();
    enabled: boolean = true;

    constructor(name: ConsentName, granted?: boolean, date?: Date, type?: ConsentType, enabled?: boolean) {
        this.name = name;
        if(!!granted) this.granted = granted;
        if(!!date) this.date = date;
        if(!!type) this.type = type;
        if(!!enabled) this.enabled = enabled;
    }

    /**
     * Map a R5 server object into a Consent object
     * @param r5 
     * @returns Consent
     */
    static mapFromReachfive(name: string, value: any): Consent {        
        return new Consent(
            name as ConsentName,
            value.granted,
            new Date(value.date),
            value.consent_type as ConsentType,
            true// TODO R5 needs to provide us if consent is enabled or not
        );
    }
}

export default Consent;