import { useTranslation, withTranslation } from "react-i18next";
import { AuthContext, AuthSession } from "../../contexts/AuthContext";
import { useContext, useState } from "react";
import { Button, ButtonType, toast, Icon, IconType } from '@om/design-system';
import { getLogout, setCookie } from "../../utils";
import { confirm } from "../../components/Confirm/ConfirmManager";
import AdminPassword from "./AdminPassword";

import './AdminUser.scss';

const ID_FORM = "admin-user-form";

enum ModeAdmin {
    Password,
    Default
}

function AdminUser(props: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(undefined);
    const [mode, setMode] = useState(ModeAdmin.Default);
    const [redirect, setRedirect] = useState(undefined as any);
    const session = useContext(AuthContext) as AuthSession;

    const submit = (event: any) => {
        event.preventDefault()

        setLoading(true);
        const { t } = props;
        const form = document.getElementById(ID_FORM) as HTMLFormElement;
        const formData = new FormData(form);
        const email: any = formData.get("email");
        setEmail(email);

        if (!email || !session.token) {
            toast.show({ content: t('login_as.no_email'), isError: true });
            setLoading(false);
            return;
        }

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-token': session.token! },
            body: JSON.stringify({ email }),
        };
        fetch('/api/login-as', options)
            .then(response => response.json())
            .then((res: any) => {
                setLoading(false);
                setRedirect(res);
            })
            .catch((err: any) => {
                setLoading(false);
                toast.show({ content: t('form.error'), isError: true });
            })
            ;
    }

    const switchUser = (event: any) => {
        event.preventDefault()
        if (!redirect) {
            return;
        }

        const { t } = props;
        confirm.show(
            t('admin_user.switch.title'),
            t('admin_user.switch.description'),
            () => {
                // logout then redirect
                setCookie('loginas', redirect.redirectUrl);
                window.location.replace(getLogout());
            }
        );
    }

    const unblacklist = (event: any) => {
        event.preventDefault()

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        };
        fetch('/api/user/unblacklist', options)
            .then(response => {
                if (response.ok) {
                    toast.show({ content: t('form.success'), isError: true });
                    return;
                }
                toast.show({ content: t('form.error'), isError: true });
            })
            .catch((err: any) => {
                toast.show({ content: t('form.error'), isError: true });
            })
            ;
    }

    const password = (event: any) => { setMode(ModeAdmin.Password) }
    const setDefaultMode = () => { setMode(ModeAdmin.Default); setRedirect(undefined); }

    return <>
        <div className="left-content-size">
            <h2>{t('admin_user.title')}</h2>
            {mode === ModeAdmin.Default &&
                <form id={ID_FORM} onSubmit={submit} className='form'>
                    <div className="col form-group">
                        <label htmlFor="email" className='required'>{t('email')}</label>
                        <input type="email" className="form-control" id="email" name="email" placeholder={t('email')} required defaultValue={email} onChange={setDefaultMode}/>
                    </div>
                    <label>{t('admin_user.description')}</label>
                    <button type="submit" disabled={loading} className="btn btn-primary btn-danger">{t('admin_user.submit')}</button>
                    {!!redirect && <>
                        <Button type={ButtonType.Secondary} onClick={switchUser}><Icon type={IconType.Person} /> {t('admin_user.switch.title')}</Button>
                        <Button type={ButtonType.Secondary} onClick={password}><Icon type={IconType.Password} /> {t('admin_user.password')}</Button>
                        <Button type={ButtonType.Secondary} onClick={unblacklist}><Icon type={IconType.Email} /> {t('admin_user.unblacklist')}</Button>
                    </>}
                </form>
            }
            {mode === ModeAdmin.Password && !!redirect &&
                <AdminPassword id={redirect.id} email={email} onCancel={setDefaultMode} />
            }
        </div>
    </>
}

AdminUser.contextType = AuthContext;
export default withTranslation()(AdminUser);