import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, ButtonType, toast } from '@om/design-system';
import { getRedirect } from '../utils';
import PasswordText from '../components/PasswordText';

const ID_FORM = "reset-pwd-form'";

function ResetPassword() {
	const [searchParams] = useSearchParams();
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [valid, setValid] = useState(false);
	const { t } = useTranslation();

	var valideR1 = false, valideR2 = false, valideR3 = false, valideR4 = false, valideR5 = false
	const minChar = /^.{8,}$/;
	const upperCase = /[A-Z]/g;
	const numbers = /[0-9]/g;
	const specialChar = /[*.!@#$%^&(){}[\]:;<>,.?\/~_+\-=|]/

	const submit = (event: any) => {
		event.preventDefault();

		const email = searchParams.get('email');
		const code = searchParams.get('verification_code');

		if (!valideR5) {
			toast.show({ content: t('newPassword.notSame'), isError: true });
			return;
		}

		if (!valideR2 || !valideR3 || !valideR4) {
			toast.show({ content: t('newPassword.security'), isError: true });
			return;
		}

		window.r5.updatePassword({
			email: email,
			verificationCode: code,
			password,
		})
			.then(() => {
				setValid(true);
				toast.show({ content: t('resetPassword.confirm') });
				setTimeout(function () {
					window.r5.logout({ redirectTo: getRedirect() });
				}, 5000);
			})
			.catch((error: any) => {
				toast.show({ content: error.errorUserMsg, isError: true })
			});
	}

	const onChangePassword = (event: any) => {
		setPassword(event.target.value);
	}

	const onChangePassword2 = (event: any) => {
		setPassword2(event.target.value);
	}

	if (password.match(minChar)) {
		valideR1 = true
	}
	if (password.match(numbers)) {
		valideR2 = true
	}
	if (password.match(upperCase)) {
		valideR3 = true
	}
	if (password.match(specialChar)) {
		valideR4 = true
	}
	if (password === password2) {
		valideR5 = true
	}

	return <div className='wrapper-content not-connected'>
		<div className='center-content-size'>
			<form id={ID_FORM} onSubmit={submit} className="form">
				<h1>{t('newPassword.reset')}</h1>
				{!valid && <>
					<PasswordText name="password" placeholder={t('newPassword.placeholder')} onChange={onChangePassword} isLabel={false} />
					<div className='x-small'>
						<div>{t('newPassword.rule')}</div>
						<div className='password-rules'>
							<div className={valideR1 ? 'rule-valide' : ''}>* {t('newPassword.ruleOne')}</div>
							<div className={valideR2 ? 'rule-valide' : ''}>* {t('newPassword.ruleTwo')}</div>
							<div className={valideR3 ? 'rule-valide' : ''}>* {t('newPassword.ruleThree')}</div>
							<div className={valideR4 ? 'rule-valide' : ''}>* {t('newPassword.ruleFour')}</div>
						</div>
					</div>

					<PasswordText name="password2" placeholder={t('newPassword.placeholder2')} onChange={onChangePassword2} isLabel={false} />
					<Button type={ButtonType.Submit}>{t('validate')}</Button>
				</>}
			</form>
			<hr />
			<Button type={ButtonType.Link} href='/' className='center'>{t('backLogin')}</Button>
		</div>
	</div>
}

export default withTranslation()(ResetPassword);