import React from 'react';
import { withTranslation } from 'react-i18next';
import GeneralForm from './GeneralForm';

class RequiredProfile extends React.Component<any> {
    render() {
        return <div className='wrapper-content not-connected'>
            <div className="center-content-size"><GeneralForm /></div>
        </div>;
    }
}

export default withTranslation()(RequiredProfile);