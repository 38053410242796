import React from 'react';
import { withTranslation } from 'react-i18next';
import Title from '../../components/Title/Title';
import { Button, ButtonType } from '@om/design-system';

class VerifyEmail extends React.Component<any> {

  render() {
    const { t } = this.props;

    return <>
      <div className='padding-xlarge text-center'>
        <Title>{t('verify_email.title')}</Title>

        <div className='space first-letter-uppercase'>
          <label>
            {t('verify_email.description')}
          </label>
        </div>
				<Button type={ButtonType.Link} href='/'>{t('verify_email.back')}</Button>
      </div>
    </>;
  }
}

export default withTranslation()(VerifyEmail);